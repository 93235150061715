<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <date-component v-model="note_date"
                            label="Дата запису"
                            req :class_="note_date ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox v-model="disable" label="Відключений" color="success" hide-details class="mt-3 pt-0"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="flat_note_type" :items="flat_note_type_select"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="flat_note_type ? '' : 'req-star'"
                      filled
                      label="Тип події"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select v-model="flat_note_result_type" :items="flat_note_result_type_select"
                      hide-details
                      :disabled="flat_note_type === 'problem_person' || flat_note_type === 'warning_document' || flat_note_type === 'comment'"
                      :class="flat_note_result_type ? '' : flat_note_type === 'problem_person' || flat_note_type === 'warning_document' || flat_note_type === 'comment' ? '' : 'req-star'"
                      filled
                      label="Результат"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text"
                          filled
                          label="Сума"
                          v-model.number="sum"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_to"
                            label="Дата до"
                            :req="false"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="notes" filled hide-details
                        label="Нотатки"
                        color="grey" auto-grow rows="2"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import flatNoteAPI from "@/utils/axios/flat_notes"

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";
import {flat_note_type_select, flat_note_result_type_select} from "@/utils/icons"

const modalDeleteId = 'checker_modal_delete'

export default {
  name: "HWP_Modal_Flat_Note",
  mixins: [ModalComponentMixin],
  data() {
    return {
      flat_note_type: this.item.flat_note_type,
      flat_note_result_type: this.flat_note_result_type,
      flat_id: this.item.flat_id,
      note_date: this.item.note_date,
      sum: this.item.sum,
      date_to: this.item.date_to,
      notes: this.item.notes,
      disable: this.item.disable,
      flat_note_type_select,
      flat_note_result_type_select
    }
  },
  methods: {
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.flat_note_type = this.item.flat_note_type
      this.flat_note_result_type = this.item.flat_note_result_type
      this.flat_id = this.item.flat_id
      this.note_date = this.item.note_date
      this.sum = this.item.sum
      this.date_to = this.item.date_to
      this.notes = this.item.notes
      this.disable = this.item.disable

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення елемента журналу подій',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    checkForm() {
      if (!this.note_date) {
        return false
      }
      if (!this.flat_id) {
        return false
      }

      if (this.flat_note_type === 'warning_document' || this.flat_note_type === 'problem_person') {
        if (!this.flat_note_result_type) {
          return false
        }
      }

      if (!this.flat_note_type) {
        return false
      }

      return true
    },
    crud_item() {
      this.formValid = this.checkForm()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля виділені червоним кольором мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        flat_note_type: this.flat_note_type,
        flat_note_result_type: this.flat_note_result_type,
        flat_id: this.flat_id,
        note_date: this.note_date,
        sum: this.sum,
        date_to: this.date_to,
        notes: this.notes,
        disable: this.disable,
      }

      if (this.isNew) {
        flatNoteAPI.create(payload)
            .then(response => response.data)
            .then(() => {
              this.closeModal('fetch')
            })
      } else {
        payload.id = this.itemId
        flatNoteAPI.update(payload)
            .then(response => response.data)
            .then(() => {
              this.closeModal('fetch')
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.flat_note_type = payload.flat_note_type
              this.flat_note_result_type = payload.flat_note_result_type
              this.flat_id = payload.flat_id
              this.note_date = payload.note_date
              this.sum = payload.sum
              this.date_to = payload.date_to
              this.notes = payload.notes
              this.disable = payload.disable
              this.itemId = payload.id
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  flatNoteAPI.remove(this.itemId)
                      .then(response => response.data)
                      .then(data => {
                        if (data) {
                          this.closeModal('fetch')
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
